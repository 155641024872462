import { Component, createRef } from "react"
import {Link} from "react-router-dom";
import {withRoute} from './withRoute'
import { connect } from 'react-redux'
import moment from 'moment'

import AlbumImage from '../components/AlbumImage'
import AlbumSlideShow from '../components/AlbumSlideShow'
import AlbumUpload from '../components/AlbumUpload'

import {isMobile} from '../utilityFunctions'
import {upsertAlbumData} from '../actionTypes/actionTypes'
import config from '../config'


class Album extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false,
      lazyLoadedImage: 6,
      albumData: null,
      largeImageData: null,
      activeSection: 'images',
      selection: [],
      usersRefresh: 0,
      mobileView: isMobile(),
      errorMessage: null,
      maxWindowScroll: 1000
    }

    this.imageThumbnailStates = {}
    this.displayLargeImage = this.displayLargeImage.bind(this)
    this.loadNextThumbnail = this.loadNextThumbnail.bind(this)
    this.addToSelection = this.addToSelection.bind(this)
    this.removeFromSelection = this.removeFromSelection.bind(this)
    this.clearSelection = this.clearSelection.bind(this)
    this.clearLargeImage = this.clearLargeImage.bind(this)
    this.downloadImageZip = this.downloadImageZip.bind(this)
    this.deleteImages = this.deleteImages.bind(this)
    this.deleteSelectedImages = this.deleteSelectedImages.bind(this)
    this.setActiveTab = this.setActiveTab.bind(this)
    this.downloadImage = this.downloadImage.bind(this)
    this.updateNewUserData = this.updateNewUserData.bind(this)
    this.saveNewUser = this.saveNewUser.bind(this)
    this.removeUser = this.removeUser.bind(this)
    this.scrollLazyLoader = this.scrollLazyLoader.bind(this)
    this.screenViewportUpdate = this.screenViewportUpdate.bind(this)
    this.isScrollRun = false
    this.newUserIdx = 0;
    this.newUserData = {
      role: 'viewer'
    }

    this.btnSelectionContainer = createRef()
    this.fixedBtnSelectionContainer = createRef()
    this.btnSelectionOriginalYPosition = null

  }

  displayLargeImage(largeImageData) {
    this.setState({
      largeImageData: largeImageData
    })
  }

  updateNewUserData(e) {
    const ele = e.target;
    this.newUserData[ele.name] = ele.value
  }

  saveNewUser() {
    if (this.newUserData['username'].length > 2) {
      const {albumKey} = this.props
      const {albumData} = this.state
      const myHeaders = new Headers({
        Authorization: 'Bearer ' + albumKey,
        'Content-type': 'application/json'
      })
      fetch(config['pyalbum_host'] + '/albums/' + albumData['pagename'] + '/users', {
        headers: myHeaders,
        method: 'POST',
        body: JSON.stringify({users: [this.newUserData]})
      })
      .then(response => response.json())
      .then(responseData => {
        this.newUserData = {
          role: 'viewer'
        }
        this.refreshUsersList()
      })
    }
  }

  removeUser(e) {

    const {albumKey} = this.props
    const {albumData} = this.state

    const myHeaders = new Headers({
      Authorization: 'Bearer ' + albumKey,
      'Content-type': 'application/json'
    })
    fetch(config['pyalbum_host'] + '/albums/' + albumData['pagename'] + '/users', {
      method: 'DELETE',
      headers: myHeaders,
      body: JSON.stringify({users: [{userid:e.target.dataset.userid}]})
    })
    .then(response => response.text())
    .then(responseData => {
      this.refreshUsersList()
    })
  }

  refreshUsersList() {
    const {albumKey} = this.props
    let {albumData, usersRefresh} = this.state
    const myHeaders = new Headers({
      Authorization: 'Bearer ' + albumKey,
      'Content-type': 'application/json'
    })
    fetch(config['pyalbum_host'] + '/albums/' + albumData['pagename'] + '/users', {
      headers: myHeaders
    })
    .then(response => response.json())
    .then(responseData => {
      albumData['users'] = responseData
      this.setState({
        'usersRefresh': usersRefresh+1
      })
    })
  }

  clearLargeImage() {
    this.setState({
      largeImageData: null
    })
  }

  loadNextThumbnail() {
    const {albumData} = this.props
    const {lazyLoadedImage} = this.state
    // console.log("going to next - " + (lazyLoadedImage + 1))
    if (Object.keys(albumData['photos']).length > lazyLoadedImage) {
      this.setState({
        'lazyLoadedImage': this.state['lazyLoadedImage'] + 1
      })
    }
  }

  screenViewportUpdate() {
    const {mobileView, largeImageData} = this.state;
    if (mobileView != isMobile()) {
      window.setTimeout(() => {
        this.isScrollRun = false
        this.setState({
          mobileView: isMobile(),
          lazyLoadedImage: isMobile() ? 0 : 3,
          // largeImageData: isMobile() ? null : largeImageData,
          largeImageData: null,
          maxWindowScroll: window.scrollY + window.innerHeight
        })
      }, 200)
    }
  }

  scrollLazyLoader(e) {
    const {maxWindowScroll} = this.state
    const screenBottom = window.scrollY + window.innerHeight
    if (screenBottom > (maxWindowScroll + 50)) {
      this.isScrollRun = true
      this.setState({
        maxWindowScroll: screenBottom
      })
    }
    this.manageFixedContainerPosition()
  }

  manageFixedContainerPosition() {
    const fixedBtnSelectionContainer = this.fixedBtnSelectionContainer.current
    if (fixedBtnSelectionContainer) {
      if (document.documentElement.scrollTop > this.btnSelectionOriginalYPosition) {
          fixedBtnSelectionContainer.style.display = 'block';
      }
      else {
        fixedBtnSelectionContainer.style.display = '';
      }
    }
  }

  getBtnSelectionContainerStartPosition() {

    if (this.btnSelectionContainer.current) {
      const btnSelectionContainer = this.btnSelectionContainer.current
      this.btnSelectionOriginalYPosition = Math.round(btnSelectionContainer.getBoundingClientRect()['y'] + document.documentElement.scrollTop)
    }
  }

  componentDidMount() {
    const {albumPagename} = this.props.params
    const {albumKey} = this.props
    if (this.props['albumData']) {

      this.setState({
        isLoaded: true,
        albumData: this.props['albumData']
      });
    }
    else if (albumKey) {
      const myHeaders = new Headers({
        Authorization: 'Bearer ' + albumKey,
      });
      fetch(config['pyalbum_host'] + '/albums/' + albumPagename, {
        'headers': myHeaders
      }).then((response) => response.json()).then(data =>{
        this.setState({
          isLoaded: true,
          albumData: data
        });
        document.title = data['title'] + ' Photos'
        this.props.upsertAlbumData(data)
      })
    }
    window.addEventListener('resize', this.screenViewportUpdate)
    window.addEventListener('scroll', this.scrollLazyLoader, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.screenViewportUpdate)
    window.removeEventListener('scroll', this.scrollLazyLoader, false)
  }

  setActiveTab(e) {
    this.newUserData = {
      role: 'viewer'
    }
    this.setState({
      activeSection: e.target.getAttribute('selecttab')
    })
  }

  addToSelection(imageFileName) {
    const {selection} = this.state
    if (selection.length < config['max_selection']) {
      selection.push(imageFileName)
      this.setState({
        selection: [
          ...selection
        ]
      })
    }
  }

  removeFromSelection(imageFile) {
    let {selection} = this.state
    selection = selection.filter(imageSelection => imageSelection != imageFile)
    this.setState({
      selection: selection
    })
  }

  downloadImage(imageFileName) {
    const {albumData, albumKey} = this.props
    const myHeaders = new Headers({
      Authorization: 'Bearer ' + albumKey,
    })

    fetch(config['pyalbum_host'] + '/img/' + albumData['pagename'] + '/' + imageFileName, {
      headers: myHeaders
    })
    .then(respose => respose.blob())
    .then(imageBlob => {
      const imageUrl = URL.createObjectURL(imageBlob, {type: imageBlob.type})
      const imageLink = document.createElement('a')
      imageLink.href = imageUrl
      imageLink.download = imageFileName
      imageLink.click()
      URL.revokeObjectURL(imageUrl)
      imageLink.remove()
    })
  }

  downloadImageZip() {
    const {albumKey} = this.props
    const {albumPagename} = this.props.params
    const {selection} = this.state
    const myHeaders = new Headers({
      Authorization: 'Bearer ' + albumKey,
      'Content-type': 'application/json',
    });
    const postData = {
      filenames: selection
    }
    fetch(config['pyalbum_host'] + '/albums/' + albumPagename + '/photos/download', {
      'headers': myHeaders,
      'method': 'POST',
      'body': JSON.stringify(postData)
    })
    .then(response => {
      if (response.ok) {
        const contentDisposition = response.headers.get('Content-disposition')
        let downloadZipName='photos.zip'
        if (contentDisposition) {
          const serverZipName = contentDisposition.split('filename=')[1]
          if (serverZipName) {
            downloadZipName = serverZipName
          }
        }
        try {
          this.setState({
            errorMessage: null
          })
          response.blob().then(blobData => {
            if (blobData.size > 100) {
              const downloadLink = document.createElement('a')
              downloadLink.style = 'display:none;'
              const zipUrl = window.URL.createObjectURL(blobData, {type: blobData.type})
              downloadLink.href = zipUrl
              downloadLink.download = downloadZipName
              downloadLink.click()
              window.URL.revokeObjectURL(zipUrl)
            }
          })
        } catch(e) {
          this.setState({
            errorMessage: 'Error downloading zip file. Please try again later. If error continues to fail please contact Worth'
          })
        }
      }
      else {
        this.setState({
          errorMessage: 'Error downloading zip file. Please try again later. If error continues to fail please contact Worth'
        })
      }
    })

  }

  renderImageThumbnails() {
    const {albumData, selection, maxWindowScroll} = this.state
    let {lazyLoadedImage} = this.state
    const {albumPagename} = this.props.params
    const selectionBtnClasses = ['btn']
    let disableSelection = false;
    if (selection.length == 0) {
      disableSelection = true;
      selectionBtnClasses.push('disabled')
    }
    const selectionButtons = [
        <input
          key={'btn-download-zip'}
          type="button"
          className={selectionBtnClasses.join(' ')}
          disabled={disableSelection}
          onClick={this.downloadImageZip}
          value="Download Zip of Selected Images" />,
        <input
          key={'btn-clear-selection'}
          type="button"
          className={selectionBtnClasses.join(' ')}
          disabled={disableSelection}
          onClick={this.clearSelection}
          value="Clear Selection" />
    ]
    if (config['userRolesOrder'].indexOf(albumData['current_user_role']) > 1) {
      selectionButtons.push(<input
          key={'btn-delete-images'}
          type="button"
          className={selectionBtnClasses.join(' ')}
          disabled={disableSelection}
          onClick={this.deleteSelectedImages}
          value="Delete Selected Images" />
       )
    }

    const selectionButtonContainer = (
      <div
        className={"btn-selection-container" + (selection.length > 0?' active': '')}
        ref={this.btnSelectionContainer}
      >
        <div>{selection.length} of {config['max_selection']} images selected</div>
        {selectionButtons}
      </div>
    )
    const fixedSelectionButtonContainer = (
      <div
        className={"btn-selection-container-fixed" + (selection.length > 0?' active': '')}
        ref={this.fixedBtnSelectionContainer}
      >
        <div>{selection.length} of {config['max_selection']}  images selected</div>
        {selectionButtons}
      </div>
    )

    const photoCount = Object.keys(albumData['photos'])
    const albumThumbnails = Object.values(albumData['photos']).map((imageData, idx) => {
      if (!this.imageThumbnailStates[idx]) {
        this.imageThumbnailStates[idx] = 0;
      }
      if (!this.isScrollRun) {
        if (this.state.mobileView) {
              // console.log('nowmobile' + idx)
        }
        else {
              // console.log('nowdesktop' + idx)

        }

        if (idx == lazyLoadedImage && this.imageThumbnailStates[idx] < 2) {
          if (this.imageThumbnailStates[idx] > 1) {
            if (photoCount > lazyLoadedImage) {
              lazyLoadedImage = idx +1;
              this.state['lazyLoadedImage'] = idx +1;
            }
          }
          else {
            this.imageThumbnailStates[idx]++
          }
        }
        else if (idx == lazyLoadedImage && this.imageThumbnailStates[idx] >= 2) {
          lazyLoadedImage = idx +1;
          this.state['lazyLoadedImage'] = idx +1;
        }
        else if (idx < lazyLoadedImage && this.imageThumbnailStates[idx] < 1) {
          // console.log('-----fixit lazyload')
          // console.log(imageData['filename'])
          // console.log( this.imageThumbnailStates[idx])
          this.imageThumbnailStates[idx] = 1;
        }
        // console.log('state - ' + this.imageThumbnailStates[idx])

      }

      return <AlbumImage
        key={imageData['filename']}
        loadImageState={this.imageThumbnailStates[idx]}
        maxWindowScroll={maxWindowScroll + 400}
        imageData={imageData}
        albumData={albumData}
        loadNextThumbnail={this.loadNextThumbnail}
        isSelected={selection.indexOf(imageData['filename']) > -1}
        addToSelection={this.addToSelection}
        removeFromSelection={this.removeFromSelection}
        enlargeImage={this.displayLargeImage}
        downloadImage={this.downloadImage}
        />
    })

    if (this.btnSelectionOriginalYPosition == null) {
      this.getBtnSelectionContainerStartPosition()
    }
    this.isScrollRun = false;
    return (
      <div className={'images-tab-section'}>
        {selectionButtonContainer}
        {fixedSelectionButtonContainer}
        <div className="image-thumbnail-container">
          {albumThumbnails}
        </div>
      </div>
    )
  }

  renderUserList() {
    const { albumData } = this.state
    const userList = albumData['users'].map(user => {
      return (
        <tr key={albumData['albumid'] + user['userid']}>
          <td>{user['username']}</td>
          <td>{user['email_address']}</td>
          <td>{user['role']}</td>
          <td>
            <a href={'/album/' + albumData['pagename'] + '?albumkey=' + user['album_key']}>
              {user['album_key']}
            </a>
          </td>
          <td>
            <input type="button" className="btn" onClick={this.removeUser} data-userid={user['userid']} value="Remove User" />
          </td>
        </tr>
      )
    })

    const userTable = (
      <table>
        <thead></thead>
        <tbody>
          {userList}
          <tr key={albumData['albumid'] + 'newUser' + this.newUserIdx}>
            <td><input
              onChange={this.updateNewUserData}
              type="text"
              name="username"
              placeholder="Username"
              autofill="Off"
              autoComplete="off"
              /></td>
            <td><input
              onChange={this.updateNewUserData}
              type="email"
              name="email_address"
              placeholder="E-mail Address"
              autofill="Off"
              autoComplete="off"
              /></td>
            <td>
              <select
                name="role"
                onChange={this.updateNewUserData}
                defaultValue={"viewer"}>
                <option value="viewer">Viewer</option>
                <option value="tagger">Tagger</option>
                <option value="sharer">Sharer</option>
                <option value="editor">Editor</option>
                <option value="admin">Administrator</option>
              </select>
            </td>
            <td></td>
            <td><input type="button" onClick={this.saveNewUser} className="btn" value="Save User" /></td>
          </tr>
        </tbody>
      </table>
    )
    return (
      <div className="users-tab-section">
        {userTable}
      </div>
    )
  }

  renderAlbumMetaData(metaKey, metaValue) {
    const metaKeyTranslate = {
      'author': 'Author',
      'photoCount': 'Total Images',
      'dateTaken': 'Photos Taken'
    }

    let returnStr = null
    if (metaValue && metaKeyTranslate[metaKey]) {
      if (metaKey == 'dateTaken') {
        const valueMoment = moment(metaValue)
        metaValue = valueMoment.format('M/D/YYYY hh:mma')
      }

      returnStr = <span>{metaKeyTranslate[metaKey]}: {metaValue}</span>
    }
    return returnStr

  }

  clearSelection() {
    const {selection} = this.state
    if (selection.length > 0) {
      this.setState({
        selection: []
      })
    }
  }

  deleteSelectedImages() {
    const {selection} = this.state;
    this.deleteImages(selection)
  }

  deleteImages(imageFileNames) {
    const { albumData, albumKey } = this.props;
    const myHeaders = new Headers({
      Authorization: 'Bearer ' + albumKey,
      'Content-type': 'application/json'
    })
    const albumImageFileNameList = Object.keys(albumData['photos'])
    const postData = {
      filenames: []
    }
    imageFileNames.forEach((imageFilename) => {
      if (albumImageFileNameList.indexOf(imageFilename) > -1) {
        postData['filenames'].push(imageFilename)
      }
    })


    fetch(config['pyalbum_host'] + '/albums/' + albumData['pagename'] + '/photos', {
      headers: myHeaders,
      body: JSON.stringify(postData),
      method: 'DELETE'
    })
    .then(response => response.json())
    .then(deletedFilenames => {
      if (deletedFilenames) {
        let selection = [
          ...this.state.selection
        ]
        deletedFilenames.forEach(filename => {
          if (albumData['photos'][filename]) {
            delete albumData['photos'][filename]
          }
          if (this.state.selection.indexOf(filename) > -1) {
            selection = selection.filter(selectionFilename => selectionFilename != filename)
          }
        })
        this.setState({
          'selection': selection
        })

      }
    })
  }

  render() {
    const {albumData, activeSection, largeImageData, selection, errorMessage} = this.state
    const {albumPagename, screenType} = this.props.params
    let returnStr = <div>Loading Album</div>;

    if (albumData) {

      let droppedFilesHtml = null
      let adminLink = <></>
      let tabs = <></>
      let section = <></>
      if (config['userRolesOrder'].indexOf(albumData['current_user_role']) > 1) {
        adminLink = <Link to={'/album/' + albumPagename + '/edit'}>Edit</Link>
        tabs = (
          <div className="tabs-container">
            <span
              className={'tab-link' + (activeSection == 'images'? ' active-tab': '')}
              selecttab="images"
              onClick={this.setActiveTab}>Images</span>
            <span
              className={'tab-link' + (activeSection == 'uploads'? ' active-tab': '')}
              selecttab="uploads"
              onClick={this.setActiveTab}>Uploads</span>
            <span
              className={'tab-link' + (activeSection == 'users'? ' active-tab': '')}
              selecttab="users"
              onClick={this.setActiveTab}>Album Users</span>
          </div>
        )
        if (activeSection == 'images') {
          section = this.renderImageThumbnails()
        }
        else if (activeSection == 'uploads') {
          section = <AlbumUpload albumData={albumData} />
          document.title = albumData['title'] + ' Uploads'
        }
        else if (activeSection == 'users') {
          section = this.renderUserList()
          document.title = albumData['title'] + ' Users'
        }
      }
      else {
        section = this.renderImageThumbnails()
      }
      let renderErrorMessage = <></>
      if (errorMessage) {
        renderErrorMessage = <div className="error message">{errorMessage}</div>
      }

      returnStr = (
        <div className="album-page">
          <AlbumSlideShow
            largeImageData={largeImageData}
            albumData={albumData}
            addToSelection={this.addToSelection}
            removeFromSelection={this.removeFromSelection}
            clearLargeImage={this.clearLargeImage}
            deleteImages={this.deleteImages}
            selection={this.state['selection']}
            downloadImage={this.downloadImage}
            />
          <h1>{albumData['title']}</h1>
          {renderErrorMessage}
          {adminLink}
          {albumData['album_text']?(<div dangerouslySetInnerHTML={{__html: albumData['album_text']}} className="album-text" />):null}
          <div className="album-metadata">
            {this.renderAlbumMetaData('author', albumData['author'])}
            {this.renderAlbumMetaData('photoCount', albumData['photos'].length)}
            {this.renderAlbumMetaData('dateTaken', albumData['date_taken'])}
          </div>
          {tabs}
          {section}
        </div>
      )
    }
    return returnStr
  }

}

const mapStateToProps = (state, ownProps) => {
  const {albumPagename} = ownProps.params
  return {
    albumKey: state.albumReducer.album_key,
    albumData: state.albumReducer.albums[albumPagename]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    upsertAlbumData: (albumData) => dispatch(upsertAlbumData(albumData))
  }
}

const AlbumWithRouter = withRoute(connect(mapStateToProps, mapDispatchToProps)(Album));
export default AlbumWithRouter