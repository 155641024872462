import {Component} from 'react'
import {connect} from 'react-redux'
import config from '../config'

class AlbumUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      droppedFileCount: 0,
      imagesUploaded: 0,
      currentUploadKey: '',
      dropText: 'Drop files here'
    }
    this.currentUploadingFile = null
    this.droppedFiles = []
    this.uploadFile = this.uploadFile.bind(this)
    this.dropFile = this.dropFile.bind(this)
    this.dragFile = this.dragFile.bind(this)
    this.uploadNextDroppedFiles = this.uploadNextDroppedFiles.bind(this)
  }

  uploadFile(e) {
    // e.target.files[0]
    // if (e.target.files[0]) {
      // let {imagesUploaded, albumData} = this.state
      // const formData = new FormData();
      // const {albumPagename} = this.props.params
      // formData.append('files', e.target.files[0])
      // const myHeaders = new Headers({
        // 'Authorization':'worthfreeman'
      // });
      // const apiUrl = config['pyalbum_host'] + '/albums/' + albumPagename + '/photos'
      // fetch(apiUrl, {
        // method:'POST',
        // headers: myHeaders,
        // body: formData

      // }).then((response) => response.json()).then(data => {
        // albumData['photos'] = Object.assign(albumData['photos'], data)
        // this.setState({
          // imagesUploaded: imagesUploaded + 1
        // })
      // })
    // }
  }

  uploadNextDroppedFiles(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    const nextDroppedFile = this.droppedFiles.shift()

    // console.log(this.droppedFiles)
    this.uploadDroppedFile(nextDroppedFile)

  }

  uploadDroppedFile(fileData) {
    const {albumData, albumKey} = this.props
    const formData = new FormData();
    this.currentUploadingFile = fileData;

    const xhr = new XMLHttpRequest()
    xhr.open('POST', config['pyalbum_host'] + '/albums/' + albumData['pagename'] + '/photos', true)
    xhr.setRequestHeader('Authorization', 'Bearer ' + albumKey)
    xhr.responseType = 'json'
    // console.log(fileData)
    formData.append('files', fileData)
    xhr.onreadystatechange = this.uploadStateChange.bind(this)
    xhr.upload.onprogress = this.uploadProgress.bind(this)
    xhr.send(formData);
  }

  uploadStateChange(xhrEvent) {
    const {albumData} = this.props
    if (xhrEvent.target.readyState === 4) {
      this.currentUploadingFile = null;
      const responseJSON = xhrEvent.target.response
      albumData['photos'] = Object.assign(albumData['photos'], responseJSON)
      // console.log(albumData['photos'])
      this.setState({
        droppedFileCount: this.droppedFiles.length
      })
      if (this.droppedFiles.length > 0) {
        window.setTimeout(this.uploadNextDroppedFiles.bind(this), 1000)
      }
    }
  }

  uploadProgress(xhrEvent) {
      const fileName = this.currentUploadingFile.name
      const percentUploaded = Math.floor((xhrEvent.loaded / xhrEvent.total) * 100)
      this.currentUploadingFile.percentUploaded = percentUploaded
      // console.log(percentUploaded)
      // console.log(fileName + '-' + percentUploaded)
      this.setState({
        currentUploadKey: fileName + '-' + percentUploaded
      })
  }


  dragFile(e) {
    e.stopPropagation();
    e.preventDefault();
    // console.log(e)
    let dropText = 'Drop Files Here'
    if (['dragover', 'dragenter'].indexOf(e.type) > -1) {
      dropText = 'Drop it here'
    }
    this.setState({
      'dropText': dropText
    })
  }

  dropFile(e) {
    e.stopPropagation();
    e.preventDefault();
    const droppedFiles = e.target.files || e.dataTransfer.files;
    this.droppedFiles = Array.from(droppedFiles)
    this.setState({
      droppedFileCount: this.droppedFiles.length
    })
  }

  renderDroppedFiles() {
    const droppedFiles = [...this.droppedFiles]
    if (this.currentUploadingFile) {
      droppedFiles.unshift(this.currentUploadingFile)
    }
    const droppedFilesPreviewArr = droppedFiles.map(droppedFile =>  {
      if (!droppedFile['image_url']) {
        droppedFile['image_url'] = URL.createObjectURL(droppedFile)
      }
      let percentCounter
      if (droppedFile['percentUploaded']) {
        percentCounter = (
          <div>
            {droppedFile['percentUploaded']}%
          </div>
        )
      }

      return (
        <div className="upload-image-thumbnail" key={droppedFile.name} style={{'display': 'inline-block'}}>
          <img src={droppedFile['image_url']} />
          <div>{droppedFile.name}</div>
          {percentCounter}
        </div>
       )
    })

    return (
      <div className={'dropped-files-section'}>
        <div>
          <input type="button" className="btn" onClick={this.uploadNextDroppedFiles} value="Upload Files" />
        </div>
        {droppedFilesPreviewArr}
      </div>
    )
  }

  render() {
    const {droppedFileCount, dropText} = this.state
    let droppedFilesHtml
    if (droppedFileCount > 0) {
      droppedFilesHtml = this.renderDroppedFiles()
    }
    return (
     <div className='album-upload-tab-section'>
       <div
          className="drag-files-container"
          onDragEnter={this.dragFile}
          onDragLeave={this.dragFile}
          onDragOver={this.dragFile}
          onDrop={this.dropFile}>
            {dropText}
        </div>
        <div className="dropped-files-section">
          {droppedFilesHtml}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    albumKey: state.albumReducer.album_key
  }
}

export default connect(mapStateToProps)(AlbumUpload)